import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Dance } from '../dance';
import { RestService } from '../rest.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-playlist',
  templateUrl: './playlist.component.html',
  styleUrls: ['./playlist.component.sass']
})
export class PlaylistComponent implements OnInit {

  @Output() listChange = new EventEmitter<Dance[]>();

  public danceList: Dance[];
  public searchId: number;
  public searchString: string;
  public searchResults: Dance[];

  constructor(private webService: RestService, private sanitizer: DomSanitizer) {
    this.danceList = [];
  }

  ngOnInit() {
  }

  //public addDance(): void {
  //  let dance: Dance;
  //  this.webService.getDance(this.searchId).subscribe((result: Dance[]) => {
  //    dance = result[0];
  //    this.danceList.push(dance);
  //    this.listChange.emit(this.danceList);
  //  }, (error) => {
  //    dance = {
  //      id: 1,
  //      dance: "",
  //      artist: "",
  //      song: "",
  //      start: 0,
  //      end: 15,
  //      URL: 'cMTxBCB_o78'
  //    }
  //    this.danceList.push(dance);
  //    this.listChange.emit(this.danceList);
  //    });
  //}

  public addDance(event): void {
    this.danceList.push(event);
    this.listChange.emit(this.danceList);
  }

  public removeDance(position: number): void {
    if (this.danceList != null && this.danceList != undefined) {
      this.danceList.splice(position, 1);
    }
  }

  public searchSong(): void {
    this.webService.searchDances(this.searchString).subscribe((results: Dance[]) => {
      this.searchResults = results;
    }, (error: any) => {
      // console.log(error);
      });
  }
  
}
