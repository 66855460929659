import { Component, OnInit } from '@angular/core';
import { RestService } from '../rest.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.sass']
})
export class HomeComponent implements OnInit {

  constructor(private restService: RestService) { }

  public result: string;

  ngOnInit() {
  }

  public getDances() {
    this.restService.getDances().subscribe((result) => {
      this.result = JSON.stringify(result);
    });
  }

}
