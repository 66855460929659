import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { AuthService } from './auth.service';
import { Observable } from 'rxjs';
import { CreatePlaylistResponse } from '../interface/create-playlist-response';
import { QueryPlaylistResponse } from '../interface/query-playlist-reponse';
import { UpdatePlaylistTitleResponse } from '../interface/update-playlist-title';
import { GetPlaylistResponse } from '../interface/get-playlist-response';
import { UpdatePlaylistTitleRequest } from '../interface/update-playlist-title-request';
import { UpdatePlaylistDescriptionResponse } from '../interface/update-playlist-description-response';
import { UpdatePlaylistDescriptionRequest } from '../interface/update-playlist-description-request';

@Injectable({
  providedIn: 'root'
})
export class PlaylistService {
  public apiRoot = 'api/v1/'
  constructor(private http: HttpClient, private auth: AuthService) { }

  public newPlaylist(): Observable<CreatePlaylistResponse> {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.currentUser.AuthToken.id_token);
    return this.http.post<CreatePlaylistResponse>(this.apiRoot + 'playlist/new', null, { headers: headers });
  }

  public queryPlaylists(): Observable<QueryPlaylistResponse> {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.currentUser.AuthToken.id_token);
    return this.http.get<QueryPlaylistResponse>(this.apiRoot + 'playlist/query', { headers: headers });
  }

  public getPlaylist(id: number): Observable<GetPlaylistResponse> {
    return this.http.get<GetPlaylistResponse>(this.apiRoot + 'playlist/' + id);
  }

  public updateTitle(request: UpdatePlaylistTitleRequest): Observable<UpdatePlaylistTitleResponse> {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.currentUser.AuthToken.id_token);
    return this.http.post<UpdatePlaylistTitleResponse>(this.apiRoot + 'updateplaylisttitle', request, { headers: headers });
  }

  public updateDescription(request: UpdatePlaylistDescriptionRequest): Observable<UpdatePlaylistDescriptionResponse> {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.auth.currentUser.AuthToken.id_token);
    return this.http.post<UpdatePlaylistDescriptionResponse>(this.apiRoot + 'updateplaylistdescription', request, { headers: headers });
  }
}
