import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Dance } from '../dance';

@Component({
  selector: 'app-search-list',
  templateUrl: './search-list.component.html',
  styleUrls: ['./search-list.component.sass']
})
export class SearchListComponent implements OnInit {

  @Input() results: Dance[];

  @Output() playlistItemAdded: EventEmitter<Dance> = new EventEmitter<Dance>();

  public exampleItem: Dance;

  constructor() {
    this.results = [];
    this.exampleItem = {
      id: 1,
      dance: 'Uni.t No More Chorus',
      artist: 'Uni.t',
      song: 'No More',
      start: 0,
      end: 123,
      URL: 'blahblah',
    }
  }

  public addToList(event): void {
    this.playlistItemAdded.emit(event);
  }
  
  ngOnInit() {
  }

}
