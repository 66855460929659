import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Dance } from '../dance';

@Component({
  selector: 'app-search-item',
  templateUrl: './search-item.component.html',
  styleUrls: ['./search-item.component.sass']
})
export class SearchItemComponent implements OnInit {

  @Input()
  item: Dance;

  @Output() searchItemAdded: EventEmitter<Dance> = new EventEmitter<Dance>();

  constructor() { }

  ngOnInit() {
  }

  public addItem(): void {
    this.searchItemAdded.emit(this.item);
  }

}
