import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule } from '@angular/common/http';

import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { SidenavComponent } from './sidenav/sidenav.component';
import { LayoutModule } from '@angular/cdk/layout';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatIconModule } from '@angular/material/icon';
import { MatInputModule } from '@angular/material/input';
import { MatListModule } from '@angular/material/list';
import { MatSidenavModule } from '@angular/material/sidenav';
import { MatSliderModule } from '@angular/material/slider';
import { MatToolbarModule } from '@angular/material/toolbar';
import { AppRoutingModule } from './app-routing.module';
import { HomeComponent } from './home/home.component';
import { PlayerComponent } from './player/player.component';
import { FormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
import { PlaylistComponent } from './playlist/playlist.component';
import { ControlsComponent } from './controls/controls.component';
import { SearchListComponent } from './search-list/search-list.component';
import { SearchItemComponent } from './search-item/search-item.component';
import { PlaylistItemComponent } from './playlist-item/playlist-item.component';
import { AddDanceComponent } from './add-dance/add-dance.component';
import { LoginComponent } from './login/login.component';
import { AuthService } from './service/auth.service';
import { SignUpComponent } from './sign-up/sign-up.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PlaylistSettingsComponent } from './playlist-settings/playlist-settings.component';
import { EditTextComponent } from './components/edit-text/edit-text.component';

@NgModule({
  declarations: [
    AppComponent,
    SidenavComponent,
    HomeComponent,
    PlayerComponent,
    PlaylistComponent,
    ControlsComponent,
    SearchListComponent,
    SearchItemComponent,
    PlaylistItemComponent,
    AddDanceComponent,
    LoginComponent,
    SignUpComponent,
    DashboardComponent,
    PlaylistSettingsComponent,
    EditTextComponent,
    
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    LayoutModule,
    MatToolbarModule,
    MatButtonModule,
    MatSidenavModule,
    MatIconModule,
    MatListModule,
    AppRoutingModule,
    HttpClientModule,
    MatCardModule,
    MatInputModule,
    FormsModule,
    MatGridListModule,
    FlexLayoutModule,
    MatSliderModule,
  ],
  providers: [AuthService],
  bootstrap: [AppComponent]
})
export class AppModule { }
