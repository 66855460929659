import { Component, OnInit, ChangeDetectorRef } from '@angular/core';
import getYouTubeID from 'get-youtube-id';

@Component({
  selector: 'app-add-dance',
  templateUrl: './add-dance.component.html',
  styleUrls: ['./add-dance.component.sass']
})
export class AddDanceComponent implements OnInit {
  constructor(private cd: ChangeDetectorRef) { }

  public danceUrl: string = "";
  public videoUrl: string = "";
  public videoLoaded: boolean = false;
  public ytPlayer: any;
  public danceStartTime: number = 0;
  public videoDuration: number = 0;
  public danceDuration: number = 0;
  public durationMax: number = 0;

  ngOnInit() {
    this.initYoutubeAPI();
  }

  public loadVideo(): void {
    this.videoUrl = getYouTubeID(this.danceUrl, { fuzzy: false });
    this.ytPlayer.loadVideoById({ videoId: this.videoUrl, startSeconds: this.danceStartTime, endSeconds: (this.danceStartTime + this.danceDuration) });
    if (this.videoUrl != null) {
      this.videoLoaded = true;
    }
    else {
      this.videoLoaded = false;
    }
    
  }

  public initYoutubeAPI(): void {

    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window['onYouTubeIframeAPIReady'] = (e) => {

      this.ytPlayer = new window['YT'].Player('player', {
        width: '100%',
        host: 'https://www.youtube.com',
        videoId: '',
        playerVars: { 'autoplay': 0, 'controls': 0 },
        events: {
          'onError': (event) => {
            this.apiError(event);
          },
          'onStateChange': (event) => { this.apiStateChange(event); }
        }
      });
    };
  }

  public apiError(event): void {
    this.videoLoaded = false;
    this.cd.detectChanges();
  }

  public apiStateChange(event): void {
    if (event.data == -1) {
      this.videoLoaded = true;
    }
    if (event.data == 1) {
      this.videoDuration = event.target.getDuration();
      this.durationMax = Math.floor(event.target.getDuration());
    }
    if (event.data == 0) {
      this.loadVideo();
    }
    this.cd.detectChanges();
  }

  public danceStartChanged(event): void {
    const danceDuration = this.danceDuration;
    this.danceStartTime = event.value;

    this.durationMax = this.videoDuration - this.danceStartTime;
    this.danceDuration = danceDuration;

    if ((this.danceStartTime + this.danceDuration) >= this.videoDuration) {
      this.danceDuration = this.videoDuration - this.danceStartTime;
    }
    this.loadVideo();
  }

  public danceEndChanged(event): void {
    this.danceDuration = event.value;
    this.loadVideo();
  }


}
