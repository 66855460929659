import { Component, OnInit, NgZone } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GoogleUser } from '../interface/google-user';
import { SignInResult } from '../interface/sign-in-result';
declare const gapi: any;

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.sass']
})
export class LoginComponent implements OnInit {
  public prevRoute: string;

  constructor(
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private authService: AuthService,
    private ngZone: NgZone,
  ) {
    gapi.load('auth2', () => {
      gapi.auth2.init();
      gapi.signin2.render('g-signin2', {
        'scope': 'profile email',
        'width': 240,
        'height': 50,
        'longtitle': true,
        'theme': 'light',
      });
      const googleAuth = gapi.auth2.getAuthInstance();
      googleAuth.then(() => {
        if (googleAuth.isSignedIn.get()) {
          let googleUser = googleAuth.currentUser.get();
          console.log(googleUser);
          localStorage.setItem('user', JSON.stringify({ AuthToken: googleUser.getAuthResponse(), Subject: googleUser.getId() } as GoogleUser));
          this.authService.signIn().subscribe((result: SignInResult) => {
            if (result.GoogleId === null) {
              this.ngZone.run(() => this.router.navigate(['sign-up']));
            }
            else {
              this.ngZone.run(() => this.router.navigate([this.prevRoute != null ? this.prevRoute : '']));
            }
          })
        }
      });
    });
  }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      this.prevRoute = paramMap.get('prevRoute');
    });
  }

  googleLogin() {
    let googleAuth = gapi.auth2.getAuthInstance();
    googleAuth.then(() => {
      googleAuth.signIn({ scope: 'profile email' }).then(googleUser => {
        localStorage.setItem('user', JSON.stringify({ AuthToken: googleUser.getAuthResponse(), Subject: googleUser.getId() } as GoogleUser));
        this.authService.signIn().subscribe((result: SignInResult) => {
          if (result.GoogleId === null) {
            this.ngZone.run(() => this.router.navigate(['sign-up']));
          } else {
            this.ngZone.run(() => this.router.navigate([this.prevRoute != null ? this.prevRoute : '']));
          }
        });
      });
    });
  }

  public signOut(): void {
    let googleAuth = gapi.auth2.getAuthInstance();
    localStorage.removeItem('user');
    googleAuth.signOut();
  }

  public testLogin(): void {
    console.log(this.authService.isAuthenticated);
  }
}
