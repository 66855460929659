import { Injectable } from '@angular/core';
import { AuthService } from '../service/auth.service';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { Observable } from 'rxjs';
import { CanActivate } from '@angular/router/';
import { SignInResult } from '../interface/sign-in-result';

@Injectable({
  providedIn: 'root'
})
export class AuthGuardService implements CanActivate {
    path: ActivatedRouteSnapshot[];
    route: ActivatedRouteSnapshot;

  constructor(private authService: AuthService, private router: Router) { }

  public canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
    if (this.authService.isAuthenticated) {
      this.authService.signIn().subscribe((result: SignInResult) => {
        if (result.GoogleId === null) {
          this.router.navigate(['/sign-up']);
          return false;
        }
      });
      return true;
    }
    this.router.navigate(['/login', { prevRoute: next.url }]);
    return false;
  }
}
