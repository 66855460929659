import { Component, OnInit, Input, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'edit-text',
  templateUrl: './edit-text.component.html',
  styleUrls: ['./edit-text.component.sass']
})
export class EditTextComponent implements OnInit {
  @Input() initial: string;
  @Input() type: string; // "text" "textarea"
  @Input() label: string;
  @Output() initialChange = new EventEmitter();

  public currentText: string;
  public isEditing: boolean = false;

  constructor() {
  }
  
  ngOnInit() {
    this.currentText = this.initial;
  }

  toggleEditing(): void {
    this.isEditing = !this.isEditing;
  }

  save(): void {
    this.initialChange.emit(this.currentText);
    this.toggleEditing();
  }

}
