import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PlaylistService } from '../service/playlist-service.service';
import { CreatePlaylistResponse } from '../interface/create-playlist-response';
import { Playlist } from '../interface/playlist';
import { QueryPlaylistResponse } from '../interface/query-playlist-reponse';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.sass']
})
export class DashboardComponent implements OnInit {

  constructor(private router: Router, private playlistService: PlaylistService) { }

  public userPlaylists: Playlist[] = [];

  ngOnInit() {
    this.playlistService.queryPlaylists().subscribe((result: QueryPlaylistResponse) => {
      this.userPlaylists = result.Playlists;
    });
  }

  newPlaylist(): void {
    //create playlist
    this.playlistService.newPlaylist().subscribe((result: CreatePlaylistResponse) => {
      console.log(result);
      this.router.navigate(['/playlistsettings/' + result.PlaylistId]);
    }, (error) => {
      console.log(error);
      });
  }

  editPlaylist(playlistId: number): void {
    this.router.navigate([`playlist/${playlistId}/edit`]);
  }
}
