import { Injectable, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { GoogleUser } from '../interface/google-user';
import { SignInResult } from '../interface/sign-in-result';
declare const gapi: any;

@Injectable()
export class AuthService {
  public apiReady: boolean = false;
  public session: any;
  public googleAuth: any;
  private User: any = null;
  private apiRoot: string = '/api/v1';


  constructor(private http: HttpClient) {
    gapi.load('client:auth2', () => {
      gapi.auth2.init().then(() => {
        this.googleAuth = gapi.auth2.getAuthInstance();
        const googleUser = this.googleAuth.currentUser.get();
        if (this.googleAuth.isSignedIn.get()) {
          const authUser: GoogleUser = { Subject: googleUser.getId(), AuthToken: googleUser.getAuthResponse() } as GoogleUser;
          localStorage.setItem('user', JSON.stringify(authUser));
        }
        else {
          localStorage.setItem('user', JSON.stringify(null));
        }
        this.googleAuth.currentUser.listen((user) => {
          this.userChanged(user);
        });
      })
    });
  }

  get currentUser(): GoogleUser {
    if (!this.User) {
      this.User = JSON.parse(localStorage.getItem('user'));
    }
    return this.User;
  }

  public signIn(): Observable<SignInResult> {
    let headers = new HttpHeaders().set('Authorization', 'Bearer ' + this.currentUser.AuthToken.id_token);
    return this.http.get<SignInResult>(`${this.apiRoot}/login`, { headers: headers });
  }

  get isAuthenticated(): boolean {
    return this.currentUser != null;
  }

  public userChanged(user: any): void {
    if (this.googleAuth.isSignedIn.get()) {
      const googleUser: GoogleUser = {
        AuthToken: user.getAuthResponse(),
        Subject: user.getId(),
      } as GoogleUser;
      localStorage.setItem('user', JSON.stringify(googleUser));
      this.User = googleUser;
    }
    else {
      localStorage.setItem('user', null);
    }  
  }
}
