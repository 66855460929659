import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { AppComponent } from './app.component';
import { PlayerComponent } from './player/player.component';
import { AddDanceComponent } from './add-dance/add-dance.component';
import { LoginComponent } from './login/login.component';
import { AuthGuardService } from './guards/auth-guard.service';
import { SignUpComponent } from './sign-up/sign-up.component';
import { DashboardComponent } from './dashboard/dashboard.component';
import { PlaylistSettingsComponent } from './playlist-settings/playlist-settings.component';


const routes: Routes = [
  {
    path: '', component: HomeComponent, canActivate: [AuthGuardService]
  },
  {
    path: 'player', component: PlayerComponent
  },
  {
    path: 'add', component: AddDanceComponent,
    canActivate: [AuthGuardService]
  },
  {
    path: 'login', component: LoginComponent
  },
  {
    path: 'sign-up', component: SignUpComponent
  },
  {
    path: 'dashboard', component: DashboardComponent
  },
  {
    path: 'playlist/:id/edit', component: PlaylistSettingsComponent, canActivate: [AuthGuardService]
  },
];

@NgModule({
  exports: [
    RouterModule
  ],
  imports: [RouterModule.forRoot(routes)]
})

export class AppRoutingModule { }
