import { Component, OnInit } from '@angular/core';
import { CreateUserService } from '../service/create-user.service';
import { Router } from '@angular/router';
import { AuthService } from '../service/auth.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './sign-up.component.html',
  styleUrls: ['./sign-up.component.sass']
})
export class SignUpComponent implements OnInit {

  constructor(private createUserService: CreateUserService,
    private authService: AuthService,
    private router: Router) { }

  public formUserName = "";
  public errorText = "";

  ngOnInit() {
  }

  public createUser(): void {
    this.createUserService.createUser(this.formUserName).subscribe((response) => {
      this.router.navigate(['/']);
    }, (error) => {
      console.log(error);
      this.errorText = error.error.message;
    });
  }
}
