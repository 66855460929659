import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map, catchError, tap } from 'rxjs/operators';
import { Dance } from './dance';


@Injectable({
  providedIn: 'root'
})

export class RestService {
  API_URL = '/api/v1';
  constructor(private http: HttpClient) { }
  getDances() {
    return this.http.get(`${this.API_URL}/list`);
  }

  getDance(id: number): Observable<Dance[]> {
    return this.http.get<Dance[]>(`${this.API_URL}/dance/${id}`);
  }

  searchDances(text: string): Observable<Dance[]> {
    return this.http.get<Dance[]>(`${this.API_URL}/search/${text}`);
  }

}
