import { Injectable } from '@angular/core';
import { GoogleAuthResponse } from '../interface/google-auth-response';
import { Observable } from 'rxjs';
import { HttpClient, HttpHeaders } from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class CreateUserService {
  public apiRoot: string = '/api/v1';
  constructor(private http: HttpClient) { }

  public createUser(newUser: string): Observable<GoogleAuthResponse> {
    let httpHeaders = new HttpHeaders().set('Authorization', 'Bearer ' + JSON.parse(localStorage.getItem('user')).AuthToken.id_token);
    return this.http.post<GoogleAuthResponse>(`${this.apiRoot}/newUser/${newUser}`, { UserName: newUser }, { headers: httpHeaders, responseType: 'json' });
  } 
}
