import { Component, OnInit } from '@angular/core';
import { PlaylistService } from '../service/playlist-service.service';
import { UpdatePlaylistTitleResponse } from '../interface/update-playlist-title';
import { Playlist } from '../interface/playlist';
import { Router, ActivatedRoute, ParamMap } from '@angular/router';
import { GetPlaylistResponse } from '../interface/get-playlist-response';
import { UpdatePlaylistTitleRequest } from '../interface/update-playlist-title-request';
import { UpdatePlaylistDescriptionRequest } from '../interface/update-playlist-description-request';
import { UpdatePlaylistDescriptionResponse } from '../interface/update-playlist-description-response';

@Component({
  selector: 'app-playlist-settings',
  templateUrl: './playlist-settings.component.html',
  styleUrls: ['./playlist-settings.component.sass']
})
export class

  PlaylistSettingsComponent implements OnInit {
  public playlist: Playlist;

  constructor(
    private playlistService: PlaylistService,
    private router: Router,
    private activatedRoute: ActivatedRoute
  ) { }

  ngOnInit() {
    this.activatedRoute.paramMap.subscribe((paramMap: ParamMap) => {
      let playlistId = parseInt(paramMap.get('id'), 10);
      this.playlistService.getPlaylist(playlistId).subscribe((result: GetPlaylistResponse) => {
        this.playlist = result.Playlist;
      });
    });
  }

  updateTitle(event: string): void {
    this.playlistService.updateTitle({ Title: event, Id: this.playlist.Id } as UpdatePlaylistTitleRequest).subscribe((result: UpdatePlaylistTitleResponse) => {
      this.playlistService.getPlaylist(this.playlist.Id).subscribe((result: GetPlaylistResponse) => {
        this.playlist = result.Playlist;
      });
    });
  }

  updateDescription(event: string): void {
    this.playlistService.updateDescription({ Id: this.playlist.Id, Description: event } as UpdatePlaylistDescriptionRequest).subscribe((result: UpdatePlaylistDescriptionResponse) => {
      this.playlistService.getPlaylist(this.playlist.Id).subscribe((result: GetPlaylistResponse) => {
        this.playlist = result.Playlist;
      });
    });
  }

}
