import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { Dance } from '../dance';

@Component({
  selector: 'app-playlist-item',
  templateUrl: './playlist-item.component.html',
  styleUrls: ['./playlist-item.component.sass']
})

export class PlaylistItemComponent implements OnInit {

  @Input() item: Dance;

  constructor() { } 

  ngOnInit() {
  }

}
