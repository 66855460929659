/*
 *  todo: loop option
 *  if: playlist is finished, and play is pushed, restart from begining
 *  create add songs components
 *  create video clip editor
 *  limit size of search results
 *  instant search? maybe
 *  ***** SEARCH UPDATE *******
 *    break up search terms on whitespace
 *    rank results by most matches desc
 *
 *  ***************************
 *
 *  better enter button actions
 *  save playlist
 *  user accounts
 *
 *  pretty playlist (add thumbnails?)
 *
 */

import { Component, OnInit, EventEmitter } from '@angular/core';
import { Dance } from '../dance';
import { RestService } from '../rest.service';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';


@Component({
  selector: 'app-player',
  templateUrl: './player.component.html',
  styleUrls: ['./player.component.sass']
})
export class PlayerComponent implements OnInit {
  public dance: Dance;
  public danceID: number;
  public videoURL = this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/');
  public colCount: number;
  public vidWidth: number;
  public rowCount: number;
  public danceCounter: number;
  public dances: Dance[] = [];
  public ytPlayer: any;
  private playerInitialized: boolean;

  constructor(private webService: RestService, private sanitizer: DomSanitizer) {
    this.danceCounter = 0;
    this.playerInitialized = false;

  }

  ngOnInit() {
    this.colCount = (window.innerWidth <= 600) ? 1 : 3;
    this.vidWidth = (this.colCount > 2) ? 2 : 1;
    this.initYoutubeAPI();
  }


  public lookUpVideo(): void {

    if (this.danceCounter < this.dances.length) {
      this.dance = this.dances[this.danceCounter];
      // this.videoURL = this.sanitizer.bypassSecurityTrustResourceUrl('https://www.youtube.com/embed/' + this.dance.URL + '?autoplay=1&enablejsapi=1&' + (this.dance.start != null ? 'start=' + this.dance.start + '&' : '') + (this.dance.end != null ? 'end=' + this.dance.end : ''));
      this.ytPlayer.loadVideoById({
        videoId: this.dance.URL,
        startSeconds: this.dance.start,
        endSeconds: this.dance.end
        }
      );
    }

  }

  public initYoutubeAPI(): void {

    var tag = document.createElement('script');
    tag.src = 'https://www.youtube.com/iframe_api';
    var firstScriptTag = document.getElementsByTagName('script')[0];
    firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);
    window['onYouTubeIframeAPIReady'] = (e) => {

      this.ytPlayer = new window['YT'].Player('player', {
        width: '100%',
        host: 'https://www.youtube.com',
        videoId: '',
        playerVars: { 'autoplay': 1 },
        events: {
          'onStateChange': (event) => {
            if (event.data == 0) {
              this.next();
            }
            ;
          },
          'onReady': this.onPlayerReady,
        }
      });
    };
  }

  public onYouTubeIframeAPIReady(): void {

  }

  public play(): void {
    this.lookUpVideo();

  }


  public onPlayerReady(event): void {
    
  }

  public next(): void {
    this.danceCounter++;
    this.play();
  }

  public listChanged(dances: Dance[]): void {
    this.dances = dances;
  }

  onResize(event): void {
    this.colCount = (event.target.innerWidth <= 600) ? 1 : 3;
    this.vidWidth = (this.colCount > 2) ? 2 : 1;
  }

  public ytStateChanged(event): void {

  }
}
