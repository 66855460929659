import { Component, OnInit, EventEmitter, Output } from '@angular/core';

@Component({
  selector: 'app-controls',
  templateUrl: './controls.component.html',
  styleUrls: ['./controls.component.sass']
})
export class ControlsComponent implements OnInit {
  @Output() playClicked = new EventEmitter<string>();
  @Output() nextClicked = new EventEmitter<string>();
  constructor() { }

  ngOnInit() {
  }

  public play(): void {
    this.playClicked.emit('play');
  }

  public next(): void {
    this.nextClicked.emit('next');
  }
}
